import React, { Component } from 'react';
import WriteButton from './WriteButton';
import TopBar from './TopBar';
import Space from './Space';
import Footer from './Footer';
import SegmentTracker from './SegmentTracking';


export default class Landing extends Component {

  constructor(props) {
    super(props);

    this.scrollToTop = this.scrollToTop.bind(this);
    this.generatePromptEvent = this.generatePromptEvent.bind(this);

    this.segmentTracker = new SegmentTracker(window);
    this.segmentTracker.trackPage('Loaded TMDWP Start Page');

    localStorage.removeItem("promptContent");
  }

  generatePromptEvent() {
    this.segmentTracker.trackEvent('Generate a Prompt');
  }

  scrollToTop() {
    this.segmentTracker.trackEvent('Lets Go TMDWA Page Bottom');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Welcome non-fixed">
        <TopBar />
        <Space l hide-680 />
        <div>
          <div className="logo">
            <h1>
              The Most <span>Dangerous</span> Writing App
            </h1>
          </div>
          <Space xs />
          <h2>
            Don’t stop writing, or all progress will be lost.
          </h2>
          <Space xs />
          <Space l hide-600 />
          <div className="buttons-wrapper">
            <div className="writeButton" onClick={this.generatePromptEvent}>
              <a href="/random-prompt-generator" className="ghost">Generate a Prompt</a>
            </div>
            <WriteButton ghost color="red" rootPageName="Promp Page" />
          </div>
        </div>
        <Space xl />
        <div className="accolades">
          <img className="accolades-full" src={require('../images/logos.png')} alt="accolades" />
          <span className="accolades-p0 as-seen-in">as seen in</span>
          <img className="accolades-p1" src={require('../images/logos_1p.png')} alt="accolades" />
          <img className="accolades-p2" src={require('../images/logos_2p.png')} alt="accolades" />
          <img className="accolades-p3" src={require('../images/logos_3p.png')} alt="accolades" />
        </div>
        <Space l with-bottom-shadow />
        {/* <div className="examples with-bottom-shadow">
          <h1>A Writing Prompt Generator with Story Ideas</h1>

          <div className="examples-single">
            <div className="examples-content">
              <h2>A First Line Generator that Inspires</h2>
              <p>Over 500+ first line suggestions to get your started with this writing app. You'll never run out of writing ideas.</p>
            </div>
            <div className="examples-image image-1"></div>
          </div>

          <div className="examples-single right">
            <div className="examples-image image-2"></div>
            <div className="examples-content right">
              <h2>A Story Prompt Generator for Everyone</h2>
              <p>Whether you're fiction writing, practicing for your novel, or simply trying to overcome writer's block, this is for you.</p>
            </div>
          </div>

          <div className="examples-single">
            <div className="examples-content">
              <h2>A Creative Random Prompt Generator</h2>
              <p>Get the creative nudge to write with over 500+ random prompts.</p>
            </div>
            <div className="examples-image image-3"></div>
          </div>

          <div className="examples-single right">
            <div className="examples-image image-4"></div>
            <div className="examples-content right">
              <h2>A Powerful Random Story Generator</h2>
              <p>Write stories whether with writing prompt inspiration or just on a timer.</p>
            </div>
          </div>
          <Space l />
        </div> */}

        <div className="bottom-box">
          {/* <div className="bottom-logo">
            <div className="bottom-logo-image" />
          </div>
          <Space l />
          <div className="logo">
            <h1>
              The Most <span>Dangerous</span> Writing Prompt Generator
            </h1>
          </div>
          <Space xs />
          <h2>
            A creative writing prompts generator
          </h2>
          <Space m />
          <div className="buttons-wrapper mb-5 pb-5">
            <div className="writeButton" onClick={this.scrollToTop}>
              <a className="blue">Let's go</a>
            </div>
          </div> */}
          <Footer mt-4 />
        </div>

      </div>
    )
  }
}
