import React, { Component } from 'react';
import Landing from './Landing';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


export default class MDWA extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/dangerous-writing-prompt-app" component={Landing} />
          <Redirect path="*" to="/dangerous-writing-prompt-app" />
        </Switch>
      </Router>
    )
  }
}
